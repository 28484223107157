// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-customize-reports-index-ts": () => import("./../../../src/components/CustomizeReports/index.ts" /* webpackChunkName: "component---src-components-customize-reports-index-ts" */),
  "component---src-components-download-reports-index-ts": () => import("./../../../src/components/DownloadReports/index.ts" /* webpackChunkName: "component---src-components-download-reports-index-ts" */),
  "component---src-components-moon-jewelry-preview-index-ts": () => import("./../../../src/components/MoonJewelryPreview/index.ts" /* webpackChunkName: "component---src-components-moon-jewelry-preview-index-ts" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dev-404-page-js": () => import("./../../../src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-pages-dev-js": () => import("./../../../src/pages/dev.js" /* webpackChunkName: "component---src-pages-dev-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

